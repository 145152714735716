import React, { useState, useContext, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import "./Landing.css";
import { Button } from "@mui/material";
import Support1 from "../../Images/support-1.svg";
import Support2 from "../../Images/support-2.svg";
import Support3 from "../../Images/support-3.svg";
import Support4 from "../../Images/support-4.svg";
import Support5 from "../../Images/support-5.svg";
import Banner from "../../Images/banner-img.webp";
import Bannerlight from "../../Images/bannerlight.webp";
import Graph from "../../Images/graph-chart.webp";
import Graphlight from "../../Images/graphlght.webp";
import Btc from "../../Images/btc.svg";
import Google from "../../Images/google-play.svg";
import Eth from "../../Images/eth.svg";
import ada from "../../Images/ada.svg";
import Chart from "../../Images/green-chart.png";
import Chart2 from "../../Images/red-chart.png";
import Trade from "../../Images/trading-view.webp";
import Cta from "../../Images/cta-img.webp";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Device from "../../Images/device-img.webp";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import AppleIcon from "@mui/icons-material/Apple";
import Crystal from "../../Images/crystal.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import BTC from "../../Images/coin-1.svg";
import USDT from "../../Images/coin-2.svg";
import Coin from "../../Images/coin-3.svg";
import { Link } from 'react-router-dom';
import Logo from "../../Images/click-logo.png";
import Logolight from "../../Images/lighrlogo2.webp";
import TradeChart from "../../Images/trade-chart.webp";
import TradeChartlight from "../../Images/tclight.webp";
// import Discover from "../../Images/discover-lap.webp";
import Discover from "../../Images/dis-lap.png";
import Discoverlight from "../../Images/discoverlight.webp";
import { useNavigate } from 'react-router-dom';
import DiscoverChart from "../../Images/discover-chart.webp";
import DiscoverChartlight from "../../Images/dclight.webp";

import WhatshotIcon from "@mui/icons-material/Whatshot";

import { ThemeContext } from '../DarkMode/ThemeProvider';
import Axios from "../Axios";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

// const theme = localStorage.getItem('theme')



const Landing = () => {

  const { isLightMode, handleDarkmode } = useContext(ThemeContext);
  const [render, setRender] = useState('')
  const [plan, setPlan] = useState([])
  const [subscribedPlan, setSubscribedPlan] = useState([])
  const [msg, setMsg] = useState()
  const navigate = useNavigate();

  const handleRender = (data) => {
    setRender(data)
  }

  const token = localStorage.getItem('Rikosta')

  const handleNavigate = async (page) => {

    const tok = await localStorage.getItem('Rikosta');

    if (tok) {
      await navigate(page);
    } else {
      await navigate('/register');
    }

  }

  const getPlan = async () => {
    try {
      const { data } = await Axios.get('/admin/getPlan')
      setPlan(data?.result)
    } catch (error) {
      console.log("🚀 ~ getPlan ~ error:", error)
    }
  }

  const getSubscibedPlan = async () => {
    try {
      const { data } = await Axios.get('/users/subscribedPlan', {
        headers: {
          Authorization: window.localStorage.getItem('Rikosta')
        }
      })
      if (data?.success == true) {
        if (data?.message === "Plan Subscribed") {
          setMsg(data.message)
          setSubscribedPlan(data?.result?.plans)
        }
        // setStatus(true)
        // setPlanId(data?.result)
      }
    } catch (error) {
      console.log("🚀 ~ getSubscibedPlan ~ error:", error)
    }
  }


  useEffect(() => {
    if (token) {

      getSubscibedPlan()
    }
    getPlan()

  }, [])


  const plans = [
   ["Done For You Trading Signals", "Investment Analysises", "Copy Trading", "Trading Fund Ventures"],
   ["Everything in Basic ", "1 Month No Fee´s", "1 Free Trading Course", "10% Discount"],
   ["Everything in Advanced", "Early Access To New Features", "2 Months No Fee´s", "30% Discount"]
  ]

  return (
    <div className="landing-full">
      <Header renderComp={handleRender} />
      <section className="banner-part">
        <div className="contain-width">
          <Box>
            <Grid item container spacing={0}>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                <h1>Profit in Crypto – Easily and Consistently</h1>
                <p>Your gateway to the crypto revolution. With just a few clicks,
                start trading profitably using the world’s most advanced trading formula.
                </p>
                {
                  token == null ?
                    <div className="free-actb-btn">
                      <Button onClick={() => handleNavigate('/market')} >Get Free Account</Button>
                    </div> :
                    <></>
                }

                <div className="supported-part display-1">
                  <p>Supported :</p>
                  <div className="supported-logos display-1">
                    <div className="supported-img">
                      <img
                        src={Support1}
                        alt="Binance-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                    <div className="supported-img">
                      <img
                        src={Support2}
                        alt="Bybit-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                    <div className="supported-img">
                      <img
                        src={Support3}
                        alt="Bybit-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                    <div className="supported-img">
                      <img
                        src={Support4}
                        alt="Bybit-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                    <div className="supported-img">
                      <img
                        src={Support5}
                        alt="Bybit-logo"
                        width={47}
                        height={47}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                <div className="banner-img">
                  {isLightMode ? <img src={Bannerlight} alt="Banner-img" fetchPriority="high" /> : <img src={Banner} alt="Banner-img" fetchPriority="high" />}
                  {/* <img src={theme ? Bannerlight : Banner} alt="Banner-img" fetchPriority="high" /> */}
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }}>
                <div className="report-detail">
                  <h2>+ 92%</h2>
                  <p>Profitable results using our signals</p>
                </div>
                <div className="report-detail">
                  <h2>$10 Million </h2>
                  <p>Daily trading volume across supported exchanges</p>
                </div>
                <div className="report-detail">
                  <h2>600+</h2>
                  <p>Cryptocurrencies supported</p>
                </div>
                <div className="report-detail">
                  <h2>+80.000</h2>
                  <p>Registered users trust Clicks & Trades</p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>
      <section className="pro-use pro-use-chg">
        <div className="contain-width">
          <Box>
            <Grid item container spacing={0} className="row-flex">
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 5 }}>
                <div className="graph-chart graph-chart-chg">
                  {isLightMode ? <img src={Graphlight} alt="gLight" /> : <img src={Graph} alt="gDark" />}

                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }} sx={{marginLeft:"auto"}}>
                <div className="trade-pro-txt" sx={{background:"red"}}>
                  <h2>Easy Profitable Crypto Trading
                  </h2>
                  <p>Clicks & Trades is built by traders, for everyone. We’re on a mission to make the crypto
                    market profitable, accessible, and safe for all. Whether you’re a beginner or a seasoned
                    investor, our platform gives you the tools to succeed.</p>
                  
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>
      <section className="pro-use">
        <div className="contain-width">
          <Box>
            <Grid item container spacing={0} className="row-flex">
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 7, xl: 7 }}>
                <div className="trade-pro-txt">
                  <h2>
                  Trading Signals, Investment Analyses, Copy Trading, Trading Funds,
                  And More!
                  </h2>
                  <div className="trade-now">
                    <Button onClick={() => handleNavigate("/market")} class="btn-primary">Trade Now</Button>
                  </div>
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 5 }}>
                <div className="graph-chart">
                  {isLightMode ? <img src={Graphlight} alt="gLight" /> : <img src={Graph} alt="gDark" />}

                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>

      <section className="how-we-help">
        <div className="contain-width">
          <Box>
            <Grid item container spacing={3}>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                <div className="trade-details display-1">
                  <div className="trade-box">
                    <div className="trade-coin display-1">
                      <div className="coin-img">
                        <img src={BTC} alt="btc" />
                      </div>
                      <div className="coin-img">
                        <p className="display-1">
                          BTC/USDT <span className="red-bg">-0.79%</span>
                        </p>
                        <p>36,641.20</p>
                        <p>36,641.20</p>
                      </div>
                    </div>
                    <div className="trade-coin display-1">
                      <div className="coin-img">
                        <img src={USDT} alt="btc" />
                      </div>
                      <div className="coin-img">
                        <p className="display-1">
                          BTC/USDT <span className="green-bg">-0.79%</span>
                        </p>
                        <p>36,641.20</p>
                        <p>36,641.20</p>
                      </div>
                    </div>
                    <div className="trade-coin display-1">
                      <div className="coin-img">
                        <img src={Coin} alt="btc" />
                      </div>
                      <div className="coin-img">
                        <p className="display-1">
                          BTC/USDT <span className="green-bg">-0.79%</span>
                        </p>
                        <p>36,641.20</p>
                        <p>36,641.20</p>
                      </div>
                    </div>
                  </div>

                  <div className="trade-direct">
                    <Link to="/market">Trade</Link>
                  </div>
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 5, xl: 5 }}>
                <div className="clicks-logo-cnt">
                  <div className="curve-line">
                    <img
                      src={isLightMode ? Logolight : Logo}
                      alt="clicks-logo"
                      width={250}
                      height={60}
                      fetchPriority="low"
                    />
                  </div>
                  <div className="help-pro-cnt">
                    <h2>How We Help You Trade Like a Pro Without Being One</h2>
                    <p>Tired of Losing? Let’s Fix That</p>
                    <p>Are you new to crypto? Or are you overwhelmed by FOMO, confusing advice, and the
                    constant volatility in the crypto world?</p>
                    <p>Even seasoned traders struggle without years of experience, the best tools, and proven
                    strategies. At Clicks & Trades, we’ve simplified trading so anyone can win consistently</p>
                  </div>
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 3, xl: 3 }}>
                <div className="trade-chart">
                  {isLightMode ? <img src={TradeChartlight} alt="trade-chart" fetchPriority="low" width={192} height={186} /> : <img src={TradeChart} alt="trade-chart" fetchPriority="low" width={192} height={186} />}
                  {/* <img src={TradeChart} alt="trade-chart" fetchPriority="low" width={192} height={186} /> */}
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>

      <section className="how-work">
        <div className="contain-width">
          <h2>How it Works:</h2>
          <div className="trading-signal">
            <Box>
              <Grid item container spacing={2}>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <h3>1. Choose a Strategy:</h3>
                  <p>Our algorithm scans the market 24/7 and only shows high-probability trades in
                    real-time. Complete with entry price, targets, and stop loss. You can also copy
                    successful traders, invest long term following our analyses and targets, or invest in
                    Trading Fund Ventures that do the work for you.</p>
                </Grid>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <div className="bitcoin-tag display-2">
                    <div className="btc-name display-1">
                      <div className="btc-img-name display-1">
                        <div className="btc-img">
                          <img src={Btc} alt="btc" />
                        </div>
                        <div className="coin-name">
                          <p>Bitcoin</p>
                          <p>BTC</p>
                        </div>
                      </div>

                      <div className="chart">
                        <img src={Chart} />
                      </div>
                      <div className="coin-value">
                        <p>$2,509.75</p>
                        <p className="green-text">+9.77%</p>
                      </div>
                    </div>
                  </div>
                  <div className="bitcoin-tag display-2">
                    <div className="btc-name display-1">
                      <div className="btc-img-name display-1">
                        <div className="btc-img">
                          <img src={Eth} alt="btc" />
                        </div>
                        <div className="coin-name">
                          <p>Ethereum</p>
                          <p>ETH</p>
                        </div>
                      </div>

                      <div className="chart">
                        <img src={Chart2} />
                      </div>
                      <div className="coin-value">
                        <p>$2,509.75</p>
                        <p className="green-text">+9.77%</p>
                      </div>
                    </div>
                  </div>
                  <div className="bitcoin-tag display-2">
                    <div className="btc-name display-1">
                      <div className="btc-img-name display-1">
                        <div className="btc-img">
                          <img src={ada} alt="btc" />
                        </div>
                        <div className="coin-name">
                          <p>Cardano</p>
                          <p>ADA</p>
                        </div>
                      </div>

                      <div className="chart">
                        <img src={Chart} />
                      </div>
                      <div className="coin-value">
                        <p>$2,509.75</p>
                        <p className="green-text">+9.77%</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className="trading-signal">
            <Box>
              <Grid item container spacing={2}>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <h3>2. Execute the Trade:</h3>
                  <p>Click and instantly start trading or investing through your favorite exchange with our preset parameters, or customize to suit your strategy. In only a few clicks your investment or trade will be set!</p>
                </Grid>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <div className="trade-coin display-1">
                    <div className="coin-img">
                      <img src={BTC} alt="btc" />
                    </div>
                    <div className="coin-img">
                      <p className="display-1">
                        BTC/USDT <span className="red-bg">-0.79%</span>
                      </p>
                      <p>36,641.20</p>
                      <p>36,641.20</p>
                    </div>
                  </div>
                  <div className="text-center copy-text">
                    <Button className="btn-primary">Copy</Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>

          <div className="trading-signal">
            <Box>
              <Grid item container spacing={2}>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <h3>3. Profit and Repeat:</h3>
                  <p>Our strategies are built for consistent profitability. Say goodbye to hours in front of a computer and the steep learning curve. With the world’s most advanced trading formula and tools, you can trade like the top 5% effortlessly in a few clicks.</p>
                </Grid>
                <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <div className="bitcoin-trade text-center">
                    <img src={Trade} alt="trade" width={329} height={352} />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </section>

      <section className="discover-endless">
        <div className="contain-width">
          <h2 style={{ color: isLightMode && '#000' }}>Discover endless possibilities in the world of Trading.</h2>
          <div className="trading-possiblity text-center">
            <img src={isLightMode ? Discoverlight : Discover} alt="discover" fetchPriority="low" />
          </div>
          <div className="trade-view text-center">
            <img src={isLightMode ? DiscoverChartlight : DiscoverChart} alt="discover-chat" fetchPriority="low" />
          </div>

          <div className="start-trade text-center display-3">

            <Button> <WhatshotIcon />Start Trading 96% Profitable</Button>
          </div>
        </div>
      </section>

      <section className="cta-box" style={{ background: isLightMode && 'none' }}>
        <div className="contain-width">
          <Grid item container spacing={2}>
            <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
              <h2>Making Crypto Easier and Profitable For Everyone</h2>
              <p>Clicks & Trades bridges the gap between the traditional financial system and the crypto revolution. Our mission is simple: to help the 95% of people who lose money in crypto start winning - consistently.</p>
              <div className="buy-token">
                <Button onClick={() => handleNavigate("/market")} class="btn-primary">Buy Token</Button>
              </div>
            </Grid>
            <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
              <div className="cta-img">
                <img src={Cta} alt="Cta" fetchPriority="low" width={392} height={431} />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="upgarde-plan">
        <div className="contain-width">
          <h2>Start Trading Like a Pro</h2>
          {
            msg == 'Plan Subscribed' ?

              <Grid item container spacing={2} className="grid-row" sx={{ justifyContent: subscribedPlan && 'center' }}>
                {subscribedPlan && subscribedPlan?.map((row, i) => (
                  <Grid item size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                    <div className="trade-pro">
                      <h5 className="display-3">{row?.Title}</h5>
                      <h3 className="display-3">{row?.Currency}{row?.Amount}</h3>
                      <div className="text-center">per {row?.Duration} {row?.type}</div>


                      <div className="pro-ticket display-3">
                        <Button>Subscribed</Button>
                      </div>
                      <List >
                        <ListItem>Free Sign-Up</ListItem>
                        <ListItem>Buy and Sell Crypto
                        </ListItem>
                        <ListItem>
                          End-to-End Encryption
                        </ListItem>
                        <ListItem>
                          2 - Factor Authentication
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                ))}
              </Grid>
              :

              <Grid item container spacing={2} className="grid-row">
                {plan && plan?.map((row, i) => (
                  <Grid item size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                    <div className="trade-pro">
                      <h5 className="display-3">{row?.Title}</h5>
                      <h3 className="display-3">{row?.Currency}{row?.Amount}</h3>
                      <div className="text-center">per {row?.Duration} {row?.type}</div>

                      <div className="pro-ticket display-3">
                        <Button>Buy</Button>
                      </div>
                      <List >
                        <List>{plans[i].map(data => (
                          <ListItem> {data} </ListItem>
                        ))}</List>
                      </List>
                    </div>
                  </Grid>
                ))
                }
                {/* <Grid item size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
              <div className="trade-pro ultra-pro">
                <h5 className="display-3">Standard</h5>
                <h3 className="display-3">€250</h3>

                <div className="pro-ticket display-3">
                  <Button>BUY</Button>
                </div>
                <List
                >
                  <ListItem>Free Sign-Up</ListItem>
                  <ListItem>Buy and Sell Crypto
                  </ListItem>
                  <ListItem>End-to-End Encryption
                  </ListItem>

                  <ListItem>
                    2 - Factor Authentication
                  </ListItem>
                  <ListItem>

                  </ListItem>

                </List>
              </div>
            </Grid> */}
              </Grid>
          }
        </div>
      </section>

      <section className="hand-device">
        <div className="contain-width">

          <div className="app-download">
            {/* <div className="crystal-card">
            <img src={Crystal} alt="crystal"/>
          </div> */}
            <Grid item container spacing={2}>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <div className="device-img">
                  <img src={Device} alt="hand-device" fetchPriority="low" />
                </div>
              </Grid>
              <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <div className="hand-crystal"><h2>Mobile Experience Section</h2></div>

                <p>Start profiting from the crypto market on your mobile device with just a few taps! Clicks & Trades brings the future of crypto trading to your fingertips. Access instant trades, seamless integrations, and real-time signals, all from your mobile device.</p>

                <div className="range">
                  <p className="trade-fast">Clicks & trade up to 100x faster</p>
                  <div className="progress-bar">
                    <div className="app-progress other-app">
                      <div className="app-bar display-2">
                        <p>Other apps</p>
                        <p>2X</p>
                      </div>
                      <BorderLinearProgress variant="determinate" value={40} />
                    </div>
                    <div className="app-progress">
                      <div className="app-bar display-2">
                        <p>EasyA</p>
                        <p>100x</p>
                      </div>
                      <BorderLinearProgress variant="determinate" value={100} />
                    </div>
                  </div>
                </div>

                <div className="app-store display-1">
                  <Link to="" className="cursor">
                    <div className="download-store display-1">
                      <AppleIcon />
                      <p>
                        Download on the <span>App Store</span>
                      </p>
                    </div>
                  </Link>
                  <Link to="" className="cursor">
                    <div className="download-store display-1">
                      <img src={Google} />
                      <p>
                        GET IT ON <span>Google Play</span>
                      </p>
                    </div>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <section className="what-cant question-part">
         <div className="contain-width">
          <Grid item container spacing={2}>
            <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} sx={{margin:"auto"}} >
               <h2>Powered by $CANT</h2>
               <h3>What Is $CANT?</h3>
               <p>$CANT isn’t just a token: it’s the backbone of Clicks & Trades. It powers our ecosystem,
               offering rewards, access, and deflationary value growth.</p>
               <ul>
                 <li><span>Access:</span> Unlock the premium features and tools of our platform.</li>
                 <li><span>Staking Rewards:</span>Earn passive income by holding and staking $CANT.</li>
                 <li><span>Deflationary Growth:</span>With every transaction, $CANT becomes more scarce, increasing its long-term value.</li>
                 <li><span>Community Governance:</span>Have a say in the platform’s future by holding $CANT.</li>
               </ul>
               <div className="buy-token">
                <Button  class="btn-primary">Buy $CANT Now</Button>
              </div>
            </Grid>
            <Grid item size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} sx={{margin:"auto",textAlign:"center"}} >
             <img src={Cta} style={{maxWidth:"100%"}} />
            </Grid>
          </Grid>
         </div>
      </section>
      <section className="faq">
        <div className="contain-width">
          <h2>FAQ</h2>
          <div className="question-part">
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>1. Why is Clicks & Trades Different?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>At Clicks & Trades, we're not just different. We’re unique</Typography>
                <Typography>Clicks & Trades is a platform built by traders, for everyone. An all-in-one solution for anyone serious about earning money with crypto. We provide everything you need for consistent success, including but not limited to:</Typography>
                  <ul>
                    <li>Premium trading signals with all parameters set-up and ready to go. Just click &
                    execute the trade based on our proven strategies.</li>
                    <li>Premium investment advice and analyses to understand what to invest in, why, and
                    for how long.</li>
                    <li>Verified crypto news to keep you ahead and avoid FOMO or FUD.</li>
                    <li>Trading competitions and challenges to sharpen your skills and break goals.</li>
                    <li>Learn-to-earn education and advanced training for those wanting to level up.</li>
                    <li>Social trading/copy trading to follow and copy the best traders on our platform.</li>
                    <li>Trading Fund Ventures where professional traders do the hard work for you.</li>
                    <li>And so much more!</li>
                  </ul>
                <Typography>This isn’t just a system. It’s a unique recipe for success that’s impossible to replicate. Clicks
& Trades is the platform that provides you with the tools, strategies, and insights you need to
succeed in crypto markets. The Consistent Profit Formula stands as the most tested,
reliable, and proven method for generating consistent profits. Whether you’re a beginner, an
experienced trader, or a long-term investor, Clicks & Trades is your go-to platform for
consistent financial success.</Typography>

              </AccordionDetails>
            </Accordion>
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>2. What is the Consistent Profit Formula?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Our Consistent Profit Formula isn’t another typical AI or technical analysis (TA)-based
                method. It’s a sophisticated, proprietary algorithm created by one of the world’s top 5%
                traders, backed by 35+ years of experience in financial markets and more than 8 years of
                successfully educating people toward financial freedom.</Typography>
                <Typography>Crafted through 20+ years of intensive market analysis, and refined over 10 years in the
                crypto markets, this formula has proven to consistently deliver profitable trades with a
                succes rate over 92%. In the past 6 years, it’s been successfully tested by hundreds of
                students before release.</Typography>
                <Typography>Unlike all the other systems relying on AI to guess patterns or simplified TA, the Consistent
                Profit Formula leverages the accumulated wisdom, insights, and strategies of elite
                multi-million-dollar traders. Professionals who’ve invested decades in perfecting their craft,
                hundreds of thousands in education, and countless hours of trial and error to master what
                works.</Typography>
                <Typography>We’ve distilled all this knowledge into a powerful, unique secret algorithm and created a
                simple, user-friendly platform so you can trade like the top 5%. You’ll instantly identify trades
                with the highest chance of success (+92%) at that moment. Simply put, it’s as easy as
                logging in, clicking, and trading profitably - consistently</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>
                3. Am I Guaranteed To Make Profit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>While no investment or trading platform can guarantee profits - because markets, including
                  crypto, are inherently volatile - Clicks & Trades is designed to maximize your chances of
                  success. Our proprietary Consistent Profit Formula has been rigorously tested and has a
                  proven track record of over 92% success across hundreds of thousands of trades.</Typography>
                  <Typography>What sets us apart is our emphasis on risk management and precision. We provide you with
                  expert-vetted signals, insights, and tools that give you a significant edge, but success still
                  depends on factors like individual execution.</Typography>  
                  <Typography>Our platform exists to help everyone - from beginners to experienced traders - navigate the
                  crypto market with confidence and consistency. While we can't guarantee profits, we do
                  guarantee access to the most advanced strategies, insights, and guidance available.
                  Thousands of users are already profiting daily with our platform, and we’re here to support
                  you every step of the way on your journey to success.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>
                4. How Does Clicks & Trades Ensure Security?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                                  At Clicks & Trades, your security and trust are our top priorities. We implement
                  state-of-the-art encryption, two-factor authentication (2FA), and end-to-end security protocols
                  to safeguard your data and assets. Our platform operates transparently, providing users with
                  clear insights into strategies and performance metrics.
                                  </Typography>
                                  <Typography>We work directly with the largest and most trusted crypto exchanges globally, meaning you
                  do not need to deposit your funds on our platform. Instead, we provide you with precise
                  insights and signals, showing you exactly where the opportunities are and how to profit from
                  them. All trading signals, investment opportunities, and funds are rigorously tested and
                  vetted by experts with decades of experience. With Clicks & Trades, you can trade
                  confidently, knowing your journey to financial success is supported by a secure and reliable
                  foundation.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="lan-accord">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>
                5. What is the $CANT Token?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>The $CANT token is the backbone of the Clicks & Trades ecosystem, designed to provide
                real value to its holders and fuel the growth of our platform. More than just a cryptocurrency,
                $CANT is a utility token deeply integrated into every aspect of Clicks & Trades, offering
                access to our features, staking rewards, community votes, and much more. With a powerful
                deflationary mechanism that reduces supply over time, $CANT not only supports the
                platform but also creates long-term value for users and investors as the ecosystem expands.
                                  </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Landing;
